/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "reliable-swiss-engineered-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reliable-swiss-engineered-hearing-aids",
    "aria-label": "reliable swiss engineered hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reliable Swiss-engineered hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Bolero is a ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "high-quality hearing aid"), " manufactured by the ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/",
    className: "c-md-a"
  }, "Swiss company Phonak"), ". It is designed for those with mild to severe hearing loss that is. Exceptionally sensitive and precisely engineered, the Bolero has been custom-made to handle environments with a high level of ambient noise, while also amplifying soft speech effectively. The “V” series features the Bolero V30, V50, V70 and V90, while the more recent “B” series includes the PR, M, P and SP models. All of them are IP67 rated, so they should be able to deal with dust and water without losing functionality. However, there are some important differences between the V and B generations, as we’ll discuss in a moment."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "operating-the-phonak-bolero-series",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#operating-the-phonak-bolero-series",
    "aria-label": "operating the phonak bolero series permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Operating the Phonak Bolero series"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Operating the Phonak Bolero is very easy. The hearing aid just uses a simple push button mechanism to turn it on, while the same button is used to control the volume level. This is intuitive for some users, but can be problematic for others who prefer separate dials for different functions, so it helps to give the device a trial before you decide upon it."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most Bolero devices are battery operated. When the charge level is low, they provide a 30 minute warning, prompting users to replace their batteries, so it makes sense for owners to carry a supply of spare batteries with them wherever they go."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On the other hand, the Bolero B comes in a rechargeable PR variety, giving you the confidence to go wherever you need to, safe in the knowledge that your hearing aid won’t run out of charge."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "phonak-bolero-b-key-specifications",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-bolero-b-key-specifications",
    "aria-label": "phonak bolero b key specifications permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Bolero B: Key Specifications"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The key features of the Phonak Bolero B are as follows:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A Behind-the-Ear (BTE) hearing aid device"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Noise reduction for comfortable listening when no speech is present"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ability to focus on a single speaker even in noisy environments, and to optimize the Signal to Noise Ratio to hone in on the person you are talking to"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Special programs for understanding speech in windy conditions"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tinnitus Balance noise generators to help with tinnitus patients"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Programs for specific environments can be saved and used whenever needed"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The Bolero PR features wireless charging, instead of standard batteries, using a lithium-ion battery that can be charged with 24 hours worth of energy."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Other Bolero B devices use zinc 312 or 13 batteries"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Binaural VoiceStream Technology enables those with deafness in one ear to easily follow conversations coming from multiple directions."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Bolero V is less sophisticated, lacking wireless charging and AutoSense, and possessing a less up to date binaural streaming system, although it remains a popular low-cost hearing aid."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Phonak Boléro now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "robust-and-ready-for-anything",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#robust-and-ready-for-anything",
    "aria-label": "robust and ready for anything permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Robust and ready for anything"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the Phonak Bolero, the speaker is encased within the hearing aid housing, instead of being placed inside the ear canal, which has some important advantages, as well as a few potential drawbacks. For one thing, the Bolero’s receiver will tend to last longer, as it isn’t directly exposed to substances within the ear. Instead, sound is passed along a tube that enters the ear, and the speaker is well protected, extending the life of the device. This kind of hearing aid tends to be more robust and suited to use in work environments or hotter settings, where perspiration becomes an issue."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "hard-to-repair-in-a-hurry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hard-to-repair-in-a-hurry",
    "aria-label": "hard to repair in a hurry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hard to repair in a hurry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On the other hand, the Bolero is much harder to fix should something go wrong with your device. When the receiver is placed inside the ear, it can easily be removed by technicians and replaced. This can be carried out while you wait, while dismantling and fixing a Bolero or similar hearing aids will take considerably longer. The Bolero tends to be a reliable device, as with all Phonak equipment, but it’s not infallible, and may not be the most convenient option if you are concerned about repairs."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "improved-sound-quality",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#improved-sound-quality",
    "aria-label": "improved sound quality permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Improved sound quality"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sound quality can also be an issue with the Phonak Bolero. That’s due to the fact that the Bolero works on a more restricted frequency range than devices like the Phonak Audeo. Some higher or lower frequency sounds may be far less audible, reducing your enjoyment of music, for example. However, with excellent functionality for handling speech and noisy environments, it makes up for this shortcoming in other areas."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "benefit-from-a-range-of-phonak-products",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#benefit-from-a-range-of-phonak-products",
    "aria-label": "benefit from a range of phonak products permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Benefit from a range of Phonak products"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Bolero isn’t designed to be used on its own, and its features can be enhanced by using it with other Phonak products. For example, movie and TV fans will be excited by the TVLink II, which beams content from TVs to hearing aids directly. The RemoteControl App can also enable you to control your Bolero using a smartphone, while ComPilots synch up Bolero ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "hearing aids with Bluetooth-enabled"), " music players and phones."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "an-adaptable-hearing-aid-with-advanced-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#an-adaptable-hearing-aid-with-advanced-features",
    "aria-label": "an adaptable hearing aid with advanced features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "An adaptable hearing aid with advanced features"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another major strength of the Phonak Bolero is its flexibility. This device has been engineered to adjust automatically to a huge variety of different environments so that when you move between locations, you won’t need to worry about changing your hearing settings. For example, AutoSense analyzes the area around you, working out where noise is coming from and how to adapt the hearing aid to best suit your needs. Coupled with the robustness noted earlier, and you have a dependable, advanced device that is suitable for work and leisure uses."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "arrange-a-call-back",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arrange-a-call-back",
    "aria-label": "arrange a call back permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Arrange a Call-Back!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you feel like the features of the Phonak Bolero could be of assistance, don’t hesitate to get in touch for a consultation and risk-free trial. You can also give our hearing experts a call. We will be happy to inform you about Phonak Bolero products and any similar hearing aids."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
